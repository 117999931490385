$(function () {
  if ( _.get($.siteConfiguration,"show_registration_benefits" ) ){
    renderMarkdown($('#registration-benefits'), $.polyglot.t('registration_benefits'));
  }


  if ( _.get($.siteConfiguration,"registration_serial_number_field.display_help" ) ){
    renderMarkdown($('#serial-number-help'), $.polyglot.t('serial_number_help_text'));
  }

  if ( _.get($.siteConfiguration,"registration_model_field.display_help" ) ){
    renderMarkdown($('#model-help'), $.polyglot.t('model_help_text'));
  }

  if (_.get($.siteConfiguration,"user_sms_optin_field.display")) {
    renderMarkdown($('#opt-in-sms-text'), $.polyglot.t('opt_in_sms_text'));
  }

  if ( _.get($.siteConfiguration,"user_optin_field.display" ) ){
    renderMarkdown($('#opt-in-text'), $.polyglot.t('opt_in_text'));
  }

  if ( _.get($.siteConfiguration,"user_optin_field.display_benefits" ) ){
    renderMarkdown($('#opt-in-benefits'), $.polyglot.t('optin_benefits'));
  }
});

$(document).ready(function() {
  renderMarkdown($('#footer-text'), $.polyglot.t('footer_text'));
});
var country_val, country_value;

var initAutocomplete = function(address_val, address_id) {
  var xmlHttp = new XMLHttpRequest();
  if($('#'+address_id).val().length > 5) { 
    searchString = address_val;
    destURL = "https://autocomplete.geocoder.api.here.com/6.2/suggest.json?app_id=nBbAC2qhUGZwO8KDTk48&app_code=J1v-Ym8B7o4Wc8PYOy56qA&query=" + searchString + "&maxresults=8&country="+country_val+"&language=en";
    xmlHttp.open("GET", destURL, true); // true for asynchronous 
    xmlHttp.send(null); 
    
    xmlHttp.onreadystatechange = function() {
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
        $("#here_autocomplete").text(xmlHttp.responseText);
        var obj = JSON.parse(xmlHttp.responseText);
        var arr=[];
        for (i=0; i<obj.suggestions.length; i++) {
          var label=obj.suggestions[i].label.split(', ').reverse().join(', ');
          var loc_id = obj.suggestions[i].locationId;
          arr.push({label:label, locationId:loc_id});
        }
        $('#'+address_id).frautoComplete(arr, {listLimit: 8});
      }
     }
   }
}

var addressAutoFill = function(loc_id, addr_id) {
   var city, state, zip, country;
   if(addr_id == "shipping_address1") {
	  city = "shipping_city"; state = "shipping_state"; country = "shipping_country"; zip = "shipping_zip";
	} else if(addr_id == "billing_address1") {
	  city = "billing_city"; state = "billing_state"; country = "billing_country"; zip = "billing_zip";
    } else {
	  city = "city"; state = "state"; country = "country"; zip = "zip";
    }
   var xhttp = new XMLHttpRequest();
   xhttp.onreadystatechange = function() {
   if (this.readyState == 4 && this.status == 200) {
     var obj = JSON.parse(this.responseText); 
     $('#'+addr_id).val('');
      var adress_with_unit = obj.response.view[0].result[0].location.address.street;
      if(obj.response.view[0].result[0].location.address.houseNumber) {
        adress_with_unit = obj.response.view[0].result[0].location.address.houseNumber + " " + obj.response.view[0].result[0].location.address.street;
      } 
      $('#'+addr_id).val(adress_with_unit);
	  if(obj.response.view[0].result[0].location.address.postalCode) {
        if(!$('#'+zip).exists() && zip == "billing_zip") { zip = "order_billing_address_zip"; }
        else if(!$('#'+zip).exists() && zip == "shipping_zip") { zip = "order_shipping_address_zip"; }

          $('#'+zip).val(obj.response.view[0].result[0].location.address.postalCode);
          $('#'+zip).valid();
      }
	  autoFillCSC(obj, city, state, country);
   }
 };
 xhttp.open("GET", "https://geocoder.api.here.com/6.2/geocode.json?locationid="+loc_id+"&jsonattributes=1&gen=9&app_id=nBbAC2qhUGZwO8KDTk48&app_code=J1v-Ym8B7o4Wc8PYOy56qA", true);
 xhttp.send();
}

var autoFillCSC = function(obj, city, state, country) {
  if(obj.response.view[0].result[0].location.address.city) {
        $('#'+city).val(obj.response.view[0].result[0].location.address.city);
        $('#'+city).valid();
      }
      if(obj.response.view[0].result[0].location.address.additionalData[1]) {
        $('#'+state).val(obj.response.view[0].result[0].location.address.additionalData[1].value);
        $('#'+state).valid();
      }
     if(obj.response.view[0].result[0].location.address.additionalData[0]) {
       $('#'+country).val(obj.response.view[0].result[0].location.address.additionalData[0].value);
     } else {
       $('#'+country).val(country_value);
     }
}

$(function() {
var ip_country = $.IPInfo.country_code;
var locale = $.currentLocale;
if((ip_country == 'CA') && (locale == 'en' || locale == 'en-ca' || locale == 'fr-ca')) {
  country_val = "CAN"; country_value = "Canada";
} else if((ip_country == 'MX') && (locale == 'en' || locale == 'es' || locale == 'es-mx')) {
  country_val = "MEX"; country_value = "Mexico";
} else {
  if(locale == 'en-ca' || locale == 'fr-ca') {
     country_val = "CAN"; country_value = "Canada";
  } else if (locale == 'es' || locale == 'es-mx'){
    country_val = "MEX"; country_value = "Mexico";
  } else {
    country_val = "USA,ASM,PRI,VIR,GUM,MNP"; country_value = "United States";
  }
}

  $("#address1, #shipping_address1, #billing_address1").on('keyup', function() {  
    var addr_val, addr_id; 
    if ($(this).val().length > 5) {
      addr_val = $(this).val(); addr_id = $(this).attr('id');
      initAutocomplete(addr_val, addr_id);
    } // If address length greater than 5 
    });
  
  $("#address1, #shipping_address1, #billing_address1").on('blur', function() {
   var addr_id = $(this).attr('id');
   if(addr_id == "shipping_address1") {
	  var loc_id = $("label[for='shipping_address1'] ul.frauto-list > li.selected").attr('data-loc');
	} else if(addr_id == "billing_address1") {
	  var loc_id = $("label[for='billing_address1'] ul.frauto-list > li.selected").attr('data-loc');
    } else {
	  var loc_id = $("label[for='address1'] ul.frauto-list > li.selected").attr('data-loc');
      }
   if(loc_id) {
     addressAutoFill(loc_id, addr_id);
   } // End of if locationid
  });

  /****** For Zip AutoFill ******/
  $("#zip, #order_shipping_address_zip, #order_billing_address_zip").on('blur', function() {
    var addr_id = $(this).attr('id');
    var zip_val;
    if(addr_id == "order_shipping_address_zip" || addr_id == "shipping_zip") {
      zip_val = $("#order_shipping_address_zip").val();
      city = "shipping_city"; state = "shipping_state"; country = "shipping_country";
    } else if(addr_id == "order_billing_address_zip" || addr_id == "billing_zip") {
      zip_val = $("#order_billing_address_zip").val();
      city = "billing_city"; state = "billing_state"; country = "billing_country";
    } else {
      zip_val = $("#zip").val();
      city = "city"; state = "state"; country = "country";
    }
     if(zip_val) {
       var xhttp = new XMLHttpRequest();
       xhttp.onreadystatechange = function() {
         if (this.readyState == 4 && this.status == 200) {
           var obj = JSON.parse(this.responseText); 
            if(obj.response.view[0]) { autoFillCSC(obj, city, state, country); }
         }
       };
     xhttp.open("GET", "https://geocoder.api.here.com/6.2/geocode.json?postalCode="+zip_val+"&jsonattributes=1&gen=9&app_id=nBbAC2qhUGZwO8KDTk48&app_code=J1v-Ym8B7o4Wc8PYOy56qA&country="+country_val, true);
     xhttp.send();
    } // End of if zip exists
  });
}); //Enf of main function


$(document).ready(function() {
  $('input[type="file"]').change(function() {
    var file_path = $('input[type="file"]').val();
    var file_name = file_path.match(/[^\/\\]*$/)[0];
    $('.upload-text').html(file_name);
  });
});

$(document).ready(function() {
  if ($("[name*='product[purchase_date]']").exists()) {
    $("[name*='product[purchase_date]']").datepicker($.extend(true, {
      changeMonth: true,
      maxDate: '+0d',
      yearRange: '1970:2018',
      changeYear: true
    }, $.datepicker.regional["en-us"] || $.datepicker.regional['']));

    $("[name*='product[purchase_date]']").rules("add", {
      dpDate: true
    });
  }

  if ($(":input[name*='user[email]']").exists()) {
    $(":input[name*='user[email]']").rules("add", {
      regex: "^[A-Za-z0-9!%&'*+/=?^_`{|}~-]+(?:.[A-Za-z0-9!%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$".replace(new RegExp("\\.", "g"), "\\\."),
      required: $(":input[name*='user[email]']")[0].required,
      messages: {
        regex: $.polyglot.t('validations.email')
      }
    });
  }

  if ($(":input[name*='user[phone]']").exists()) {
    var phone_field = $(":input[name*='user[phone]']");
    var isRequired = phone_field[0].required

    if ( _.get($.siteConfiguration,"user_phone_field.display" )){
      phone_field.data("required_phone", isRequired);
      phone_field.rules('add', {
        strictPhone: isRequired
      });

      $("#registria_form").on('blur change keyup', phone_field, function() {
        if (phone_field.val().indexOf("_") > 0) {
          phone_field.data("valid_phone", false);
        }
        else {
          phone_field.data("valid_phone", true);
        }
      });
    }
  }

  if ($("section.survey div.radio-group input").exists()) {
    $("section.survey div.radio-group input").rules('add', {
      required: true,
      messages: {
        required: "<div class='survey-invalid bg-info text-white p-8 mb-8'><i class='fas fa-star-exclamation mr-4'></i>" + $.polyglot.t('validations.survey_fill_out') + "</div>"
      }
    });
  }
});

$(window).load(function() {
  if ($('#purchase_date').length > 0) {
    $('#purchase_date').rules('add', {
      messages: {
        // futureDate correct here?
        futureDate: $.polyglot.t('validations.required')
      }
    });
    $('#purchase_date').on('keyup', function() {
      if((isFutureDate($('#purchase_date').val())) ){
        $('#purchase_date').rules('add', {
          messages: {
            futureDate: $.polyglot.t('validations.date_not_in_future')
          }
        });
      } else {
        $('#purchase_date').rules('add', {
          messages: {
            // futureDate correct here?
            futureDate: $.polyglot.t('validations.required')
          }
        });
      }
    });
  }

  /* Check given purchase date is future date or not */
  function isFutureDate(idate){
    var today = new Date().getTime(),
    idate = idate.split("/");
    idate = new Date(idate[2], idate[1] - 1, idate[0]).getTime();
    //console.log("idate:"+idate);
    return (today - idate) < 0 ? true : false;
  }
});

$(window).load(function() {
  if (!_.endsWith($.currentLocale, '-ca')) {
    $.validator.addMethod('strictZip', function(value, element) {
      return $(element).data("valid_zip");
    }, $.polyglot.t('validations.invalid_zip'));

    if ($('#zip').exists()) {
      $("#zip").data("valid_zip", false);
      $('#zip').rules('add', {
        strictZip: true
      });
    }

    $("#registria_form").on('blur change keyup', '#zip', function() {
      var regEx = /^[0-9]{5}$/i;
      if (regEx.test($("#zip").val()))
        $("#zip").data("valid_zip", true);
      else
        $("#zip").data("valid_zip", false);
    });
  }
});


$(document).ready(function(){
  $("#installer_checkbox").change(function(){
    if($(this).prop("checked") == true){
      $("#header_text").text($.polyglot.t('header.homeowner_information'));
    }else{
      $("#header_text").text($.polyglot.t('header.your_information'));
    }
  });
});

$(document).ready(function($){
  $('.btn-input-help').click(function(){
    $(".icon", this).toggleClass('fa-question-circle fa-angle-up');
  });
});